<template>
  <div v-if="loading" class="mask" style="width: 100vw; height: 100vh"></div>
</template>
<script>
export default {
  name: "blur-mask",
  props: {
    loading: Boolean,
  },
};
</script>

<style scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Przezroczysty kolor tła z poziomem przezroczystości */
  backdrop-filter: blur(2px); /* Rozmycie tła */
}
</style>
