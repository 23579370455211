import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/global.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import CustomIcon from "./assets/kanbanBoard.png";
import mask from "./components/mask.vue"; // Importuj loading mask komponent
import spinner from "./components/loadingSpinner.vue";
import footer from "./components/footer.vue";
import uppermenu from "./components/uppermenu.vue";

const app = createApp(App);
app.use(router);

// Dodaj konfigurację Vue Toastification
const toastOptions = {
  timeout: 3500,
  position: "top-center",
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: CustomIcon,
};
app.use(Toast, toastOptions);

// Zarejestruj swój komponent globalnie
app.component("blur-mask", mask);
app.component("spinner", spinner);
app.component("stopka", footer);
app.component("upper-menu", uppermenu);

app.mount("#app");
