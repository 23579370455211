<template>
  <footer>
    <a
      class="logolink"
      href="https://github.com/JakubRoss"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        class="footerLogo"
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        width="27mm"
        height="27mm"
        version="1.1"
        style="
          shape-rendering: geometricPrecision;
          text-rendering: geometricPrecision;
          image-rendering: optimizeQuality;
          fill-rule: evenodd;
          clip-rule: evenodd;
        "
        viewBox="0 0 27 27"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
      >
        <g id="Warstwa_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <path
            d="M13.4614 0.8c-7.1477,0 -12.926,5.8208 -12.926,13.022 0,5.7563 3.7023,10.6288 8.8384,12.3534l0.4302 0c0.3207,-0.0844 0.4472,-0.3721 0.4472,-0.6249 0,-0.3019 -0.0212,-1.3367 -0.0212,-2.4149 -3.5957,0.7763 -4.3445,-1.5523 -4.3445,-1.5523 -0.5779,-1.5092 -1.434,-1.8971 -1.434,-1.8971 -1.1769,-0.7977 0.0857,-0.7977 0.0857,-0.7977 1.3055,0.0863 1.9905,1.3367 1.9905,1.3367 1.1554,1.9833 3.0173,1.4229 3.7663,1.0779 0.1069,-0.8408 0.4495,-1.4229 0.8133,-1.7463 -2.8678,-0.3019 -5.8851,-1.4229 -5.8851,-6.4249 0,-1.4229 0.5133,-2.5871 1.3266,-3.4925 -0.1283,-0.3233 -0.5779,-1.6603 0.1286,-3.4496 0,0 1.0914,-0.345 3.5523,1.3367 1.0533,-0.2841 2.1412,-0.4313 3.232,-0.4313 1.0914,0 2.204,0.1511 3.2314,0.4313 2.4612,-1.6817 3.5526,-1.3367 3.5526,-1.3367 0.7064,1.7894 0.2566,3.1263 0.1283,3.4496 0.8348,0.9054 1.3269,2.0696 1.3269,3.4925 0,5.0019 -3.0173,6.1013 -5.9066,6.4249 0.471,0.4096 0.8774,1.1856 0.8774,2.4146 0,1.7463 -0.0212,3.1477 -0.0212,3.5788 0,0.2528 0.1265,0.5404 0.4466,0.6252l0.4317 0c5.1356,-1.7253 8.8375,-6.5974 8.8375,-12.3534 0.0208,-7.2012 -5.7786,-13.022 -12.9049,-13.022z"
          />
        </g>
      </svg>
    </a>
  </footer>
</template>
<style>
.footerLogo {
  fill: #e0e0e050;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 50%;
  right: 50%;
  height: 40px;
  z-index: 999;
}
</style>
