<template>
  <div
    id="spinner"
    v-if="loading"
    class="d-flex justify-content-center align-items-center"
  >
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow" style="width: 3rem; height: 3rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading-spinner",
  props: {
    loading: Boolean,
  },
};
</script>
<style scoped>
#spinner {
  position: fixed;
  height: 100%;
  width: 100%;
}
</style>
