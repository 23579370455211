<template>
  <spinner :loading="spinner" style="z-index: 999"></spinner>
  <blur-mask :loading="mask" style="z-index: 9"></blur-mask>
  <div class="workspace" style="margin-top: 5vh">
    <!-- FOREACH BOARD IN BOARDS-->
    <div
      v-for="(board, index) in boards"
      :key="index"
      class="card workspace"
      style="max-width: 10vw; min-width: 250px"
    >
      <!-- Trzy kropeczi -->
      <div class="dropdown">
        <button
          class="btn btn-secondary btn-icon"
          type="button"
          id="boardOptionsDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="fas fa-ellipsis-h"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="boardOptionsDropdown">
          <li>
            <a
              class="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#editModal"
              @click="transferBoardToModal(board)"
              >Edit name</a
            >
          </li>
          <li>
            <a
              class="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#deleteModal"
              @click="transferBoardToModal(board)"
              >Delete</a
            >
          </li>
        </ul>
      </div>
      <!-- END Trzy kropeczi -->
      <div class="card-container" @click="openBoard(board)">
        <img src="/img/card1.png" class="card-img-top" alt="board card" />
        <div class="card-body">
          <h5 class="card-title">{{ board.name }}</h5>
        </div>
      </div>
    </div>
    <!--END FOREACH BOARD IN BOARDS-->
    <!-- DELETING Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              {{ modalBoard.name }}
            </h1>
          </div>
          <div class="modal-body">
            Are you sure you want to remove this board?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="deleteBoard(modalBoard)"
            >
              Yes, delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END DELETING MODAL-->

    <!--EDITING NAME MODAL-->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">
              Enter new {{ modalBoard.name }} name
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="newBoardName" class="form-label"
                >New Board Name:</label
              >
              <input
                type="text"
                class="form-control"
                id="newBoardName"
                v-model="editedBoardName"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="editBoard(modalBoard, editedBoardName)"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--END EDITING NAME MODAL-->
    <!--Adding NAME MODAL-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Enter board name</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="newBoardName" class="form-label"
                >New Board Name:</label
              >
              <input
                type="text"
                class="form-control"
                id="newBoardName"
                v-model="editedBoardName"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="addBoard(editedBoardName)"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--END EDITING NAME MODAL-->
    <!--ADDING CARD-->
    <div
      class="card workspace"
      style="max-width: 10vw; min-width: 250px"
      @click=""
      data-bs-toggle="modal"
      data-bs-target="#addModal"
    >
      <img src="/img/card2.png" class="card-img-top" alt="add Card" />
      <div class="card-body">
        <h5 class="card-title">Add</h5>
        <p class="card-text">a new board to your workspace</p>
      </div>
    </div>
    <!--END OF ADDING CARD-->
  </div>
</template>
<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      boards: [], // Tablice użytkownika
      newBoard: {
        // Nowa tablica, którą użytkownik dodaje
        name: "", // Nazwa nowej tablicy
      },
      modalBoard: {
        id: 1,
        name: "deflaut",
        createdAt: Date.now,
      }, // Tablica, która jest wybrana do usunięcia
    };
  },
  async created() {
    this.mask = true;
    this.spinner = true;
    await this.fetchUserBoards(); // Wywołanie metody do pobrania tablic użytkownika przy tworzeniu komponentu
    this.mask = false;
    this.spinner = false;
  },
  methods: {
    async addBoard(boardName) {
      if (boardName !== null && boardName.trim() !== "") {
        const data = {
          name: boardName, // Przygotowanie danych do wysłania - nazwa nowej tablicy
        };
        const token = localStorage.getItem("token"); // Pobranie tokena autoryzacyjnego z local storage
        const headers = {
          Authorization: `Bearer ${token}`, // Ustawienie nagłówka z tokenem
        };
        axios
          .post("https://flobird.azurewebsites.net/boards", data, { headers }) // Wywołanie żądania POST, aby dodać nową tablicę
          .then(() => {
            this.newBoard.name = ""; // Zresetowanie wartości pola nazwy nowej tablicy
            this.fetchUserBoards(); // Wywołanie metody do ponownego pobrania tablic użytkownika
            this.toast.success("Board added successfully");
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.status === 404
            ) {
              console.error(error);

              const errorPopup = error.response.data;
              this.errorPopup = errorPopup;
              this.toast.error(errorPopup);
            } else if (
              error.response &&
              error.response.data.errors &&
              error.response.status === 400
            ) {
              const errorPopup = Object.values(error.response.data.errors)
                .map((messages) => messages.join(". "))
                .join(". ");
              console.error(error);
              this.errorPopup = errorPopup;
              this.toast.error(errorPopup);
            } else if (error.code === "ERR_NETWORK") {
              console.error(error);
              this.toast.error(error.message);
            } else {
              console.error(error);
              this.toast.error("User does not exist");
            }
          });
      } else {
        this.toast.error(error.response.data);
      }
    },
    async fetchUserBoards() {
      const token = localStorage.getItem("token"); // Pobranie tokena autoryzacyjnego z local storage
      const headers = {
        Authorization: `Bearer ${token}`, // Ustawienie nagłówka z tokenem
      };
      axios
        .get("https://flobird.azurewebsites.net/boards", { headers }) // Wywołanie żądania GET, aby pobrać tablice użytkownika
        .then((response) => {
          this.boards = response.data; // Przypisanie pobranych tablic do właściwości "boards"
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.toast.error("Error fetching user boards");
        });
    },
    openBoard(board) {
      this.$router.push(`/board/${board.id}`); // Przekierowanie do strony tablicy
    },
    transferBoardToModal(board) {
      this.modalBoard = board;
    },
    async editBoard(board, newOne) {
      const currentName = board.name;
      const newName = newOne;
      if (
        newName !== null &&
        newName.trim() !== "" &&
        newName !== currentName
      ) {
        try {
          const token = localStorage.getItem("token");
          const headers = {
            Authorization: `Bearer ${token}`,
          };
          const data = {
            name: newName,
          };
          await axios
            .put(
              `https://flobird.azurewebsites.net/boards?boardId=${board.id}`,
              data,
              { headers }
            )
            .then((response) => {
              board.name = newName.trim();
              console.log("Board name updated successfully:", response);
              this.toast.success("Board name updated successfully");
            });
        } catch (error) {
          console.error(error);
          if (
            error.response &&
            error.response.data.errors &&
            error.response.status === 400
          ) {
            const errorPopup = Object.values(error.response.data.errors)
              .map((messages) => messages.join(". "))
              .join(". ");
            console.error(error);
            this.errorPopup = errorPopup;
            this.toast.error(errorPopup);
          } else {
            this.toast.error(error.response.data);
          }
        }
      }
    },
    async deleteBoard(board) {
      const token = localStorage.getItem("token"); // Pobranie tokena autoryzacyjnego z local storage
      const boardId = board.id; // ID tablicy do usunięcia
      const headers = {
        Authorization: `Bearer ${token}`, // Ustawienie nagłówka z tokenem
      };
      axios
        .delete(`https://flobird.azurewebsites.net/boards?boardId=${boardId}`, {
          headers,
        }) // Wywołanie żądania DELETE, aby usunąć tablicę
        .then(() => {
          this.boards = this.boards.filter((board) => board.id !== boardId); // Usunięcie tablicy z listy tablic
          this.toast.success("Board deleted successfully");
        })
        .catch((error) => {
          console.error(error);
          this.toast.error(error.response.data);
        });
    },
  },
};
</script>

<style>
.btn-icon {
  background-color: transparent !important;
  padding: 0 !important;
  border: none !important;
}
.workspace {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
.card-img-top {
  width: 95%;
  height: auto;
}

.card.workspace {
  /* Definicja początkowego stanu elementu */
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.card.workspace:hover {
  /* Definicja stanu po najechaniu myszką */
  transform: scale(1.05); /* Lekkie powiększenie elementu */
  background-color: rgba(0, 0, 0, 0.1); /* Pociemnienie koloru tła */
}

@media (max-width: 768px) {
  .workspace {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
</style>
