import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import Profile from "../views/Profile.vue";
import Workspace from "../views/Workspace.vue";
import NotFound from "../views/NotFound.vue";
import Board from "../views/Board.vue";

const routes = [
  {
    path: "/board/:boardId",
    name: "Board",
    component: Board,
    meta: { requiresAuth: true }, // Dodanie metadanych requiresAuth dla chronionej trasy,
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    // meta: { requiresAuth: false }, // Ustawienie wymagania autoryzacji na false
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true }, // Dodanie metadanych requiresAuth dla chronionej trasy
  },
  {
    path: "/",
    name: "Workspace",
    component: Workspace,
    meta: { requiresAuth: true }, // Dodanie metadanych requiresAuth dla chronionej trasy
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  //catchall 404
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: NotFound,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(new Date().getTime());
  // // Jeśli trasa wymaga uwierzytelnienia
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log("1");
    const token = localStorage.getItem("token");
    if (token === null) {
      console.log("2");
      next("/login");
    } else {
      console.log("3");
      // Dekodowanie tokenu
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      // Pobranie czasu wygaśnięcia tokenu
      const TokenExpirationTimeInSeconds = decodedToken.exp + 300;
      //sprawdzamy czy token jest wazny (data wygasniecia jest mniejsza od daty aktualnej)
      if (TokenExpirationTimeInSeconds < new Date().getTime() / 1000) {
        console.log("4");
        localStorage.removeItem("token");
        localStorage.removeItem("avatar");
        localStorage.removeItem("login");
        next("/login");
      } else {
        console.log("5");
        next();
      }
    }
  }
  // Jeśli trasa nie wymaga uwierzytelnienia
  else {
    console.log("6");
    next();
  }
});

export default router;
